import Link from 'next/link';
import CartIcon from "./cart/CartIcon";
import React, { useState, useContext, useEffect } from 'react';
import NextImage from '../image';

import logo from '../../public/images/v2/Logo.svg';
import Minicart from './cart/Minicart';
import MobileNav from './MobileNav';
import { AppContext } from './context/AppContext';
import { SEARCH_PRODUCT_BY_STRING } from '../queries/search-products';
import { useLazyQuery } from '@apollo/client';
import SearchSidebar from './SearchSidebar';
import { PopupContext } from './context/PopupContext';
import { useTranslation } from 'next-i18next';

const Nav = (props) => {
    const { t } = useTranslation('common');
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [cart, setCart, miniCartOpen, setMiniCartOpen] = useContext(AppContext);
    const { productPopup, setProductPopup } = useContext(PopupContext);
    const [searchBarOpen, setSearchBarOpen] = useState(false);
    const [query, setQuery] = useState('');
    const [results, setResults] = useState([]);
    const [menuData, setMenuData] = useState(props?.menuData?.data?.menu?.menuItems?.nodes);
    const [subSubMenuHover, setSubSubMenuHover] = useState({});

    const mobileMenuData = props?.mobileMenuData?.data?.menu?.menuItems?.nodes;
    const { search = true, ctaButton = true } = props;

    useEffect(() => {
        setMenuData(props?.menuData?.data?.menu?.menuItems?.nodes?.filter(item => item?.parentId === null));
    }, [props.menuData]);

    const [getProducts, { loading }] = useLazyQuery(SEARCH_PRODUCT_BY_STRING, {
        variables: { slug: query },
        fetchPolicy: 'no-cache',
        notifyOnNetworkStatusChange: true,
        onCompleted: (data) => {
            // Filter out all products where the name does not match the query
            data.products.nodes = data.products.nodes.filter((product) => {
                return product.name.toLowerCase().includes(query.toLowerCase());
            });
            setResults(data);
        },
        onError: (error) => {
            console.log(error);
        }
    });

    useEffect(() => {
        if (query.length !== 0) {
            getProducts({
                variables: { slug: query }
            });
        }
    }, [query, getProducts]);

    return (
        <div className="px-4 py-2 md:py-3.5 mx-auto container">
            <div className="flex items-center justify-between">
                <button
                    aria-label="Open Menu"
                    title="Open Menu"
                    style={{ WebkitTapHighlightColor: 'rgba(0, 0, 0, 0)' }}
                    className="w-1/3 p-2 -mr-1 lg:hidden transition duration-200 rounded focus:outline-none focus:shadow-outline hover:bg-deep-purple-50 focus:bg-deep-purple-50"
                    onClick={() => setIsMenuOpen(true)}
                >
                    <svg className="w-5 text-gray-600" viewBox="0 0 24 24">
                        <path
                            fill="currentColor"
                            d="M23,13H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,13,23,13z"
                        />
                        <path
                            fill="currentColor"
                            d="M23,6H1C0.4,6,0,5.6,0,5s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,6,23,6z"
                        />
                        <path
                            fill="currentColor"
                            d="M23,20H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,20,23,20z"
                        />
                    </svg>
                </button>
                <div style={{ WebkitTapHighlightColor: 'rgba(0, 0, 0, 0)' }} className="w-1/3 lg:w-auto flex items-center">
                    <Link
                        passHref
                        href="/"
                        aria-label="Home"
                        title="Home"
                        className="inline-flex items-center m-auto lg:m-0 lg:mr-12">

                        <NextImage priority quality={100} width={150} height={45} altText="logo" sourceUrl={logo.src} />

                    </Link>
                    <ul className="items-center hidden space-x-8 text-sm lg:flex">
                        {menuData?.map((item, i) => (
                            <React.Fragment key={i}>
                                {item?.childItems?.nodes?.length > 0 ? (
                                    <li
                                        onMouseLeave={() => setSubSubMenuHover({})}
                                        className="py-2 group font-medium tracking-wide text-gray-700 transition-colors duration-200 flex hover:text-gray-900">
                                        <div className="relative cursor-pointer flex after:content-[''] after:absolute after:-bottom-10 after:-left-[10%] after:w-[120%] after:h-10">
                                            <span className='flex-grow'>{item.label}</span>
                                            <svg fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-4 h-fit my-auto ml-2 duration-200 transform group-hover:rotate-180">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
                                            </svg>
                                        </div>
                                        <div
                                            className='container hidden group-hover:block transition-all -z-10 absolute mt-[2.75rem] inset-x-0'>
                                            <div className='bg-white rounded-b-[0.25em] mx-auto transform shadow-lg is-active'>
                                                <div className='container relative grid grid-cols-1 mx-auto sm:grid-cols-6'>
                                                    <nav className='col-span-2 px-4 py-8 gap-y-10 sm:grid-cols-2 sm:gap-x-8 sm:pb-12 sm:pt-4 sm:px-6 lg:px-8 xl:pr-12'>
                                                        <ul className='flex flex-col divide-y divide-gray-200'>
                                                            {item?.childItems?.nodes?.map((childItem, i) => (
                                                                <li className="text-base justify-between cursor-pointer flex font-medium" key={i}
                                                                    onMouseEnter={() => setSubSubMenuHover(childItem)}
                                                                >
                                                                    <Link
                                                                        passHref
                                                                        href={childItem?.path}
                                                                        className="hover:text-gray-900 flex justify-between hover:bg-gray-100 bg-opacity-50 px-4 py-2 w-full">

                                                                        {childItem?.label}
                                                                        {childItem?.childItems?.nodes?.length > 0 && (
                                                                            <svg fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-4 h-fit my-auto ml-2 duration-200 transform -rotate-90">
                                                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
                                                                            </svg>
                                                                        )}
                                                                    </Link>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </nav>
                                                    {subSubMenuHover?.childItems?.nodes?.length > 0 && (
                                                        <nav className={`col-span-2 px-4 py-8 gap-y-10 sm:grid-cols-2 sm:gap-x-8 sm:pt-4 sm:px-6 lg:px-8 xl:pr-12`}>
                                                            <ul className='flex flex-col divide-y divide-gray-200'>
                                                                {subSubMenuHover?.childItems?.nodes?.map((childItem, i) => (
                                                                    <li className="text-base justify-between cursor-pointer flex font-medium" key={i}>
                                                                        <Link
                                                                            passHref
                                                                            href={childItem?.path}
                                                                            className="hover:text-gray-900 hover:bg-gray-100 bg-opacity-50 px-4 py-2 w-full">
                                                                            {childItem?.label}
                                                                        </Link>
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        </nav>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                ) : (
                                    <li>
                                        <Link
                                            href={item.path}
                                            aria-label={item.label}
                                            title={item.label}
                                            className={`py-2 font-medium tracking-wide text-gray-700 transition-colors duration-200`}>

                                            {item.label}

                                        </Link>
                                    </li>
                                )}
                            </React.Fragment>
                        ))}
                    </ul>
                </div>
                <div className="w-1/3 justify-end lg:w-auto inline-flex items-center border-0 py-1 px-2 focus:outline-none rounded text-base md:mt-0">
                    {ctaButton && (
                        <button onClick={() => setProductPopup({ ...productPopup, open: true })} className='mr-4 hidden lg:block btn-primary lightgreen py-3 px-5'>
                            {t('nav.designnow')}
                        </button>
                    )}
                    {search && (
                        <div className='relative flex mr-2 md:mr-0 w-1/2 md:w-1/3 lg:w-auto'>
                            <button aria-label='Search' style={{ maxHeight: '26px', height: 'fit-content' }} onClick={() => setSearchBarOpen(!searchBarOpen)} className={`z-10 flex my-auto ml-auto sm:ml-4 mr-2 sm:mr-4 lg:w-auto border-0 focus:outline-none rounded text-base`}>
                                <svg width="26" height="26" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11 19.4375C15.4183 19.4375 19 15.8558 19 11.4375C19 7.01922 15.4183 3.4375 11 3.4375C6.58172 3.4375 3 7.01922 3 11.4375C3 15.8558 6.58172 19.4375 11 19.4375Z" stroke="#111827" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M21 21.4374L16.65 17.0874" stroke="#111827" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </button>
                            <SearchSidebar loading={loading} searchBarOpen={searchBarOpen} setSearchBarOpen={setSearchBarOpen} query={query} setQuery={setQuery} results={results} />
                        </div>
                    )}
                    <button aria-label='Minicart' style={{ WebkitTapHighlightColor: 'rgba(0, 0, 0, 0)' }} onClick={() => setMiniCartOpen(true)} className={`${ctaButton ? 'w-1/3' : 'w-fit'} lg:w-auto sm:ml-4 h-7 inline-flex border-0 focus:outline-none rounded text-base md:mt-0`}>
                        <CartIcon />
                    </button>
                </div>
                <Minicart />
                <MobileNav t={t} setIsMenuOpen={setIsMenuOpen} isMenuOpen={isMenuOpen} mobileMenuData={mobileMenuData} />
            </div>
        </div>
    );
}

export default Nav;

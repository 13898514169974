import React, { useState, useEffect } from 'react';
export const PopupContext = React.createContext([
	{},
	() => {}
]);

export const PopupProvider = ( props ) => {

	const [productPopup, setProductPopup] = useState({
        open: false,
    });

	return (
		<PopupContext.Provider value={ {productPopup, setProductPopup} }>
			{ props.children }
		</PopupContext.Provider>
	);
};

import { ArrowDown } from "../icons";

const QtySelection = ({ curQty , handleOnChange, mr = 'mr-4'}) => {

    const runCallback = (cb) => {
		return cb();
	};

    return (
            <div className={`relative ${mr}`}>
                <select
                    onChange={handleOnChange}
                    value={curQty}
                    name="quantity"
                    id='qty'
                    className="bg-white pb-2 text-sm lg:text-sm focus:outline-none appearance-none inline-block rounded leading-tight w-10"
                >
                    {
                        runCallback(() => {
                            const row = [];
                            for (var i = 1; i <= 20; i++) {
                                row.push(<option key={i} value={i}>{i}</option>);
                            }
                            return row;
                        })
                    }
                </select>
                <span className="absolute right-0 mr-1 text-gray-500 pointer-events-none" style={{top: '5%'}}>
                    <ArrowDown width={24} height={24} className="fill-current"/>
                </span>
            </div>
    );
}

export default QtySelection;
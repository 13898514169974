import NextImage from '../image';
import Link from 'next/link';

import { useTranslation } from 'next-i18next';
import { allOptions, allPaymentOptions } from './FooterIcons';
import Spinner from './global/Spinner';
import React, { Fragment, useState } from 'react';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { domainConfig } from '../../domains.config';

const Footer = (props) => {
    const { t } = useTranslation('common');
    const { locale } = useRouter();
    const domain = domainConfig[locale];
    const options = t('footer.paymentmethods') in allOptions ? allOptions[t('footer.paymentmethods')] : allOptions['others'];
    const { newsletter = true } = props;

    const [status, setStatus] = useState(null);
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (email && email.indexOf("@") > -1) {
            setLoading(true);
            const response = await fetch(`/wp-json/api/v1/subscribe_newsletter?email=${email}`, {
                method: 'POST',
            });
            if (response.status === 200) {
                const data = await response.json();
                setStatus(data.result);
                if (data.result === 'success') {
                    setEmail('');
                }
            } else {
                setStatus('error');
            }
            setLoading(false);
        }
    }

    const resetStatus = () => {
        if (status) {
            setStatus(null);
        }
    };

    return <>
        {loading && <Spinner />}
        <footer className='mt-8'>
            {newsletter && t('footer.newsletter.enabled') === 'true' ? (
                <div className='beige flex gap-8'>
                    <div className='container justify-between flex-col sm:flex-row flex py-10'>
                        <h3 className='text-2xl sm:text-3xl mb-4 sm:mb-0 w-full sm:w-3/5 max-w-[20em] font-bold'>{t('footer.newsletter')}</h3>
                        <div className='my-auto'>
                            <input
                                required
                                type="email"
                                name="email"
                                style={{ height: '50px' }}
                                className='input-green outline-green-800 align-middle no-rounded-right text-base md:text-sm p-3 w-full sm:w-72 transparent font-500'
                                placeholder='Voer hier uw emailadres in...'
                                onChange={e => { setEmail(e.target.value); resetStatus(); }} />
                            <button
                                style={{ height: '50px' }}
                                className='btn-primary align-middle mt-4 sm:mt-0 sm:-ml-2'
                                onClick={handleSubmit}
                            >
                                {t('footer.newsletter.subscribe')}
                            </button>
                            {status === 'success' ? <p className='mt-4 max-w-[25rem] text-sm'>{t('newsletter.succes')}</p> : null}
                            {status === 'error' ? <p className='mt-4 max-w-[25rem] text-sm'>{t('newsletter.error')}</p> : null}
                        </div>
                    </div>
                </div>
            ) : null}
            <div className='green pt-12'>
                <div className="container mb-6">
                    <NextImage width={150} height={45} altText="logo" sourceUrl="/images/v2/footer/Logo.svg" />
                </div>
                <div className='container pb-3 flex flex-col md:flex-row sm:gap-8 md:gap-16 leading-[1.35rem] text-white text-sm'>
                    <div className='w-full md:w-1/2 flex flex-col sm:flex-row sm:gap-16'>
                        <div className='w-full md:w-1/2'>
                            <p>{t('footer.description')}</p>
                            <div className='hidden sm:flex gap-3 mt-4'>
                                {domain.facebook && (
                                    <a target="_blank" href="https://www.facebook.com/whereithappenednl" rel="noopener noreferrer">
                                        <NextImage sourceUrl="/images/v2/footer/et_facebook.svg" alt="Facebook" width={30} height={30} />
                                    </a>
                                )}
                                {domain.instagram && (
                                    <a target="_blank" href="https://www.instagram.com/whereithappenednl/" rel="noopener noreferrer">
                                        <NextImage sourceUrl="/images/v2/footer/et_instagram.svg" alt="Instagram" width={30} height={30} />
                                    </a>
                                )}
                            </div>
                        </div>
                        <div className='w-full my-4 sm:my-0 md:w-1/2'>
                            <ul>
                                <li className='flex gap-2'>
                                    <NextImage sourceUrl={'/images/v2/footer/checkmark.svg'} alt="Checkmark" width={20} height={20} />
                                    <span className='md:w-4/5'>{t('footer.usp1')}</span>
                                </li>
                                <li className='flex mt-3 gap-2'>
                                    <NextImage sourceUrl={'/images/v2/footer/checkmark.svg'} alt="Checkmark" width={20} height={20} />
                                    <span className='md:w-4/5'>{t('footer.usp2')}</span>
                                </li>
                                <li className='flex mt-3 gap-2'>
                                    <NextImage sourceUrl={'/images/v2/footer/checkmark.svg'} alt="Checkmark" width={20} height={20} />
                                    <span className='md:w-4/5'>{t('footer.usp3')}</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className='w-full md:w-1/2'>
                        <div className='flex sm:mt-0 sm:mb-8 md:mb-0 flex-col sm:flex-row gap-4 sm:gap-8'>
                            <div className='sm:w-1/3'>
                                <h4 className='text-lg font-500'>{t('footer.products')}</h4>
                                <ul className='mt-2'>
                                    {props?.menuData?.footerProducts?.data?.menu?.menuItems?.nodes?.map((item, i) => (
                                        <li className='my-1' key={i}>
                                            <Link passHref href={item.path} title={item.label}>

                                                {item.label}

                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            </div>

                            <div className='sm:w-1/3'>
                                <h4 className='text-lg font-500'>{t('footer.information')}</h4>
                                <ul className='mt-2'>
                                    {props?.menuData?.footerMenu2?.data?.menu?.menuItems?.nodes?.map((item, i) => (
                                        <li className='my-1' key={i}>
                                            <Link passHref href={item.path} title={item.label}>

                                                {item.label}

                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            </div>

                            <div className='sm:w-1/3'>
                                <h4 className='text-lg font-500'>{t('footer.customer')}</h4>
                                <ul className='mt-2'>
                                    {props?.menuData?.footerMenu3?.data?.menu?.menuItems?.nodes?.map((item, i) => (
                                        <li className='my-1' key={i}>
                                            <Link passHref href={item.path} title={item.label}>

                                                {item.label}

                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            </div>

                        </div>
                    </div>
                    <div className='flex sm:hidden gap-3 mt-4'>
                        <a target="_blank" href={t('footer.facebook')} rel="noopener noreferrer">
                            <NextImage sourceUrl="/images/v2/footer/et_facebook.svg" alt="Facebook" width={30} height={30} />
                        </a>
                        <a target="_blank" href={t('footer.instagram')} rel="noopener noreferrer">
                            <NextImage sourceUrl="/images/v2/footer/et_instagram.svg" alt="Instagram" width={30} height={30} />
                        </a>
                    </div>
                </div>
                <div className='container flex sm:gap-16 leading-[1.35rem] text-sm'>
                    <div className='border-b w-full border-opacity-25 border-white'>
                    </div>
                </div>

                <div className='container pt-4 pb-4 flex flex-col md:flex-row justify-between md:gap-16 text-white leading-[1.35rem] text-sm'>
                    <ul className='flex gap-1 my-auto md:w-1/2 xl:w-1/3'>
                        {props?.menuData?.footerMenu1?.data?.menu?.menuItems?.nodes?.map((item, i) => (
                            <Fragment key={i}>
                                {i > 0 && <li>|</li>}
                                <li className='my-auto'>
                                    <Link passHref href={item.path} title={item.label}>

                                        {item.label}

                                    </Link>
                                </li>
                            </Fragment>
                        ))}
                    </ul>

                    <div className='flex my-auto flex-col xl:flex-row gap-4 md:w-1/2 xl:w-2/3 xl:justify-between'>
                        <div className='max-md:mt-4 flex gap-4 md:gap-6 md:justify-end xl:justify-center items-center'>
                            <a className='flex' href="//whereithappened.nl">
                                <Image
                                    src="/images/footer/netherlands.png"
                                    alt="Where It Happened NL"
                                    width={23}
                                    height={23}
                                    className="object-cover object-center h-fit my-auto flex-1"
                                />
                                <span className="ml-2 flex-1 my-auto">Nederlands</span>
                            </a>
                            <a className='flex' href="//whereithappened.co.uk">
                                <Image
                                    src="/images/footer/uk.png"
                                    alt="Where It Happened UK"
                                    width={23}
                                    height={23}
                                    className="object-cover object-center h-fit my-auto flex-1"
                                />
                                <span className="ml-2 flex-1 my-auto">English</span>
                            </a>
                            <a className='flex' href="//whereithappened.de">
                                <Image
                                    src="/images/footer/germany.png"
                                    alt="Where It Happened DE"
                                    width={23}
                                    height={23}
                                    className="object-cover object-center h-fit my-auto flex-1"
                                />
                                <span className="ml-2 flex-1 my-auto">Deutsch</span>
                            </a>
                        </div>

                        <div className='flex md:justify-end md:mt-0 gap-2 my-auto'>
                            {allPaymentOptions.map((item, i) => {
                                if (!options.includes(item.name)) return;
                                return <NextImage key={i} width={item.width} height={item.height} altText={item.name} sourceUrl={item.imageUrl} />
                            })}
                            {/* {allShippingMethods.map((item, i) => {
                        if (!options.includes(item.name)) return;
                        return <div key={"sm_" + i}>
                            <NextImage width={item.width} height={item.height} altText={item.name} sourceUrl={item.imageUrl} />
                        </div>
                    })} */}
                        </div>
                    </div>
                </div>

            </div>

            <div className='bg-text-color py-3'>
                <div className='leading-[1.35rem] container flex justify-between flex-col sm:flex-row text-white text-xs'>
                    <span>&copy; {new Date().getFullYear()} {t('footer.copyright')}</span>
                    <Link passHref href="/sitemap/" title={t('footer.sitemap')}>
                        <span>{t('footer.sitemap')}</span>
                    </Link>
                </div>
            </div>
        </footer>
    </>;
};

export default Footer;

const upcomingPromotions = [{
    name: 'Black Friday',
    startDate: '2023-11-16',
    endDate: '2023-11-25',
    discountPercentage: 20,
    bgColor: '#000000',
    textColor: '#ffffff',
    discountLabel: 'Black Friday: 20% korting op het perfecte cadeau!'
}, {
    name: 'Black Friday',
    startDate: '2023-11-25',
    endDate: '2023-11-28',
    discountPercentage: 20,
    bgColor: '#000000',
    textColor: '#ffffff',
    discountLabel: 'Black Friday: 20% korting op het perfecte cadeau!'
}]

export const getActivePromotion = () => {
    const today = new Date()
    const activePromotion = upcomingPromotions.find(promotion => {
        const startDate = new Date(promotion.startDate)
        const endDate = new Date(promotion.endDate)
        return startDate <= today && today <= endDate
    })

    if (!activePromotion) {
        return null
    }

    return activePromotion
}
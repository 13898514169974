import { AppProvider } from "./context/AppContext";
import { PopupContext, PopupProvider } from "./context/PopupContext";
import Header from "./Header";
import Footer from "./Footer";
import clients from "./ApolloClient";
import Router, { useRouter } from "next/router";
import NProgress from "nprogress";
import { ApolloProvider } from "@apollo/client";
import { useTranslation } from 'next-i18next';
import { useContext, useEffect, useRef, useState } from "react";
import NextImage from "../image";
import Slider from "react-slick";
import Head from "next/head";
import Link from "next/link";
import { Cross } from "./icons";
import { allOptions, allPaymentOptions } from "./FooterIcons";
import Image from "next/image";
import { domainConfig } from "../../domains.config";
import { getActivePromotion } from "../promotionConfig";
import PromotionBanner from "./PromotionBanner";

Router.events.on("routeChangeStart", () => NProgress.start());
Router.events.on("routeChangeComplete", () => NProgress.done());
Router.events.on("routeChangeError", () => NProgress.done());

const settings = {
  dots: false,
  fade: false,
  arrows: false,
  autoplay: true,
  infinite: true,
  autoplaySpeed: 6000,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1
};

const Layout = (props) => {
  const { locale, asPath } = useRouter();

  const { t } = useTranslation('common');
  const bannerRef = useRef(null);
  const [stickyClass, setStickyClass] = useState("");

  const activePromotion = getActivePromotion();

  useEffect(() => {
    const sticky = bannerRef.current.offsetHeight;
    const scrollCallBack = window.addEventListener("scroll", () => {
      if (window.pageYOffset > sticky) {
        if (stickyClass === "") {
          setStickyClass("sticky");
        }
      } else {
        setStickyClass("");
      }
    });
    return () => {
      window.removeEventListener("scroll", scrollCallBack);
    };
  }, [stickyClass]);

  return (
    <AppProvider>
      <PopupProvider>
        <ApolloProvider client={clients[locale]}>
          <div className="page_wrapper min-h-screen">
            {/* Self referring canonical */}
            <Head>
              <link rel="canonical" href={`https://${domainConfig[locale].backendDomain}${asPath}`} />
            </Head>
            <ProductPopup t={t} />

            {activePromotion && (
              <PromotionBanner t={t} />
            )}

            {props.banner === false ? null :
              <div ref={bannerRef} className="banner uppercase text-xs h-10 flex">
                <div className="container relative my-auto hidden sm:flex">
                  <Reviews t={t} />
                  <div className="relative sm:w-1/2 lg:w-1/3">
                    <Slider {...settings}>
                      <span className="my-auto h-8 !flex mx-auto"><strong className="font-secundary block mx-auto mt-auto mb-auto">{t('header.shippingtime')}</strong></span>
                      <span className="my-auto h-8 !flex mx-auto"><strong className="font-secundary block mx-auto mt-2 mb-auto">{t('header.boldpromotion')}{t('header.promotion')}</strong></span>
                    </Slider>
                  </div>
                  <PaymentMethods t={t} />
                </div>
                <div className="sm:hidden relative container my-auto">
                  <Slider {...settings}>
                    <span className="my-auto mx-auto"><strong className="font-secundary">{t('header.shippingtime')}</strong></span>
                    <span className="my-auto h-8 !flex mx-auto"><strong className="font-secundary my-auto mx-auto">{t('header.boldpromotion')}{t('header.promotion')}</strong></span>
                    <Reviews t={t} />
                  </Slider>
                </div>
              </div>}
            {props.header === false ? null : <Header search={props.search} ctaButton={props.ctaButton} sticky={stickyClass} mobileMenuData={props?.menuData?.mobileHeaderMenu || props?.menuData?.headerMenu} menuData={props?.menuData?.headerMenu} />}
            {props.children}
            {props.footer === false ? null : <Footer menuData={{
              footerMenu1: props?.menuData?.footerMenu1,
              footerMenu2: props?.menuData?.footerMenu2,
              footerMenu3: props?.menuData?.footerMenu3,
              footerProducts: props?.menuData?.footerProducts,
            }}
              newsletter={props.newsletter}
            />}
          </div>
        </ApolloProvider>
      </PopupProvider>
    </AppProvider>
  );
};

const Reviews = ({ t }) => (
  <div className="w-full max-sm:h-8 sm:w-1/4 lg:w-1/3 flex">
    <div className="my-auto mx-auto sm:mx-0 h-4 flex">
      <a className="flex" href={t('trustpilot.link')} target="_blank" rel="noopener noreferrer">
        <span style={{ fontWeight: 500 }} className="font-secundary">
          <span className="sm:hidden lg:inline-block">{t('rated.at')}
          </span> {t('rating')}
        </span>
        <NextImage className="object-contain" sourceUrl="/images/v2/header/stars-4.5.svg" altText="stars" width={100} height={16} />
      </a>
    </div>
  </div >
);

const PaymentMethods = ({ t }) => {
  const options = t('footer.paymentmethods') in allOptions ? allOptions[t('footer.paymentmethods')] : allOptions['others'];

  return (
    <div className="sm:w-1/4 lg:w-1/3 w-full justify-center sm:justify-end flex gap-2">
      {allPaymentOptions.map((item, i) => {
        if (!options.includes(item.name)) return;
        return <Image style={{
          filter: item.invertable ? "invert(100%)" : "none"
        }} key={i} width={item.width * 0.7} height={item.height * 0.7} alt={item.name} src={item.imageUrl} />
      })}
    </div>
  )
};

const PaymentIcon = ({ url, width, height, alt }) => (
  <div className="my-auto">
    <NextImage sourceUrl={url} height={height} width={width} alt={alt} />
  </div>
);

const ProductPopup = ({ t }) => {
  const { productPopup, setProductPopup } = useContext(PopupContext);

  return (
    <>
      <div className={`${productPopup?.open ? 'opacity-1 visible pointer-events-auto' : 'opacity-0 invisible pointer-events-none'} fixed top-0 left-0 z-50 justify-center w-full h-full sm:h-full sm:items-center modal flex current-active transition-all duration-25 ease-in-out opacity-1`}>
        <div onClick={() => setProductPopup({ ...productPopup, open: false })} className="absolute inset-0 bg-black opacity-75"></div>

        <div className="flex w-full max-w-6xl max-h-screen p-4">
          <div className="relative p-8 overflow-x-auto bg-white rounded shadow-lg w-full">

            <div className="absolute top-0 right-0 z-50 block pt-3 pr-3">
              <Cross onClick={() => setProductPopup({ ...productPopup, open: false })} circleFill="#7e7e7e" fill="#ffffff" className="cursor-pointer z-50 hover:text-black focus:outline-none focus:ring-2 focus:ring-offset-2" width={32} height={32} />
            </div>

            <div className="relative">
              <div className="grid grid-flow-row gap-8 sm:grid-cols-3 lg:gap-12 md:overflow-x-scroll lg:overflow-x-auto md:grid-flow-col md:scroll-snap-x md:grid-rows-1 md:auto-cols-md lg:auto-cols-auto lg:show-scrollbar md:hide-scrollbar md:mask-right lg:mask-none">
                <ProductPopupItem productPopup={productPopup} setProductPopup={setProductPopup} t={t} src={t("productpopup.source1")} link={t("productpopup.link1")} title={t("productpopup.title1")} text={t("productpopup.text1")} />
                <ProductPopupItem productPopup={productPopup} setProductPopup={setProductPopup} t={t} src={t("productpopup.source2")} link={t("productpopup.link2")} title={t("productpopup.title2")} text={t("productpopup.text2")} />
                <ProductPopupItem newProduct={new Date() < new Date("2023-09-30")} productPopup={productPopup} setProductPopup={setProductPopup} t={t} src={t("productpopup.source3")} link={t("productpopup.link3")} title={t("productpopup.title3")} text={t("productpopup.text3")} />
              </div>
            </div>

          </div>
        </div>

      </div>
    </>
  );
};

const ProductPopupItem = ({ title, text, link, src, t, productPopup, setProductPopup, newProduct = false }) => (
  (<Link
    href={link}
    passHref
    onClick={() => setProductPopup({ ...productPopup, open: false })}
    className="relative max-w-sm mx-auto flex flex-col flex-grow md:flex-shrink active">

    <div className="w-full relative p-4 flex justify-center bg-gray-100 md:p-8">
      <div className="flex w-fit h-fit shadow-sm">
        <NextImage sourceUrl={src} altText={title} width={160} height={200} />
      </div>
      {/* "New" in circle */}
      {newProduct && (
        <div className="absolute top-0 right-0 z-50 block pt-3 pr-3">
          <div className="flex items-center font-primary font-primary justify-center text-white bg-opacity-85 w-12 h-12 text-xs font-medium bg-[#111827] rounded-full"><span>{t('propductpopup.new')}</span></div>
        </div>
      )}
    </div>
    <div className="flex flex-col flex-grow pt-4 space-y-2 text-center md:space-y-4">
      <h2 className="text-xl leading-tight group-hover:underline">{title}</h2>
      <p className="block flex-grow text-xs leading-tight md:text-sm">{text}</p>
      <button className="w-full max-w-fit mx-auto text-xs sm:text-sm md:text-[13px] text-center btn btn-primary">{t('productpopup.design')} {title}</button>
    </div>

  </Link>)
)

export default Layout;

import { ApolloClient, ApolloLink, InMemoryCache, createHttpLink } from "@apollo/client";
import { RetryLink } from '@apollo/client/link/retry';
import { i18n } from '../../next-i18next.config';
import { domainConfig } from '../../domains.config';
/**
 * Middleware operation
 * If we have a session token in localStorage, add it to the GraphQL request as a Session header.
 */
export const middleware = new ApolloLink((operation, forward) => {
	/**
	 * If session data exist in local storage, set value as session header.
	 */
	const session = (typeof window != "undefined") ? localStorage.getItem("woo-session") : null;

	if (session) {
		operation.setContext(({ headers = {} }) => ({
			headers: {
				"woocommerce-session": `Session ${session}`
			}
		}));
	}

	return forward(operation);

});

/**
 * Afterware operation.
 *
 * This catches the incoming session token and stores it in localStorage, for future GraphQL requests.
 */
export const afterware = new ApolloLink((operation, forward) => {

	return forward(operation).map(response => {

		if (typeof window == "undefined") {
			return response;
		}

		/**
		 * Check for session header and update session in local storage accordingly.
		 */
		const context = operation.getContext();
		const { response: { headers } } = context;
		const session = headers.get("woocommerce-session");

		if (session) {

			// Remove session data if session destroyed.
			if ("false" === session) {

				localStorage.removeItem("woo-session");

				// Update session new data if changed.
			} else if (localStorage.getItem("woo-session") !== session) {

				localStorage.setItem("woo-session", headers.get("woocommerce-session"));

			}
		}

		return response;

	});
});

// include RetryLink
const retryLink = new RetryLink({
	attempts: {
		max: 3,
		retryIf: (error, _operation) => !!error
	}
});

const { domains } = i18n;
// Apollo GraphQL client.
const clients = {};

for (let index = 0; index < domains.length; index++) {
	const domain = domains[index];
	const backendDomain = domainConfig[domain.defaultLocale].backendDomain;
	clients[domain.defaultLocale] = new ApolloClient({
		link: ApolloLink.from([
			retryLink,
			middleware,
			afterware,
			createHttpLink({
				uri: `https://${backendDomain}/graphql`,
				fetch: fetch
			})
		]),
		cache: new InMemoryCache(),
	});
}

export default clients;

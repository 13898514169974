import { Fragment, useState, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import Link from 'next/dist/client/link';
import NextImage from '../image';
import Spinner from './global/Spinner';
import { useTranslation } from 'next-i18next';

const SearchSidebar = ({ searchBarOpen, setSearchBarOpen, results, query, setQuery, loading }) => {
    const [open, setOpen] = useState(false);
    const { t } = useTranslation('common');

    useEffect(() => {
        if (searchBarOpen) {
            setOpen(true);
            setSearchBarOpen(false)
        }
    }, [searchBarOpen, setSearchBarOpen]);

    return (
        <>
            <Transition.Root show={open} as={Fragment}>
                <Dialog as="div" className="fixed inset-0 overflow-hidden z-50" onClose={setOpen}>
                    <div className="absolute inset-0 overflow-hidden">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-in-out duration-500"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in-out duration-500"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                        </Transition.Child>

                        <div className="fixed inset-y-0 right-0 left-10 sm:left-auto max-w-full flex">
                            <Transition.Child
                                as={Fragment}
                                enter="transform transition ease-in-out duration-500 sm:duration-700"
                                enterFrom="translate-x-full"
                                enterTo="translate-x-0"
                                leave="transform transition ease-in-out duration-500 sm:duration-700"
                                leaveFrom="translate-x-0"
                                leaveTo="translate-x-full"
                            >
                                <div className="w-screen max-w-md" style={{ zIndex: '9999999' }}>
                                    <div className="h-full bg-white shadow-xl overflow-y-scroll">
                                        <div className="flex flex-col flex-1 py-6 overflow-y-auto px-4 sm:px-6">
                                            {loading && <Spinner />}
                                            <div className="ml-3 h-7 flex items-center">
                                                <button
                                                    type="button"
                                                    style={{ WebkitTapHighlightColor: 'rgba(0, 0, 0, 0)' }}
                                                    className="-m-2 p-2 text-gray-600 hover:text-gray-700 focus:outline-none focus-visible:outline-none"
                                                    onClick={() => setOpen(false)}
                                                >
                                                    <span className="sr-only">Close</span>
                                                    <XIcon className="h-6 w-6" aria-hidden="true" />
                                                </button>
                                            </div>
                                        </div>
                                        <div className='pb-6 px-4 sm:px-6'>
                                            <div className='pb-4 mb-4 border-b border-gray-300 border-opacity-30'>
                                                <h2 className='text-lg font-bold mb-2'>{t('nav.search')}:</h2>
                                                <input value={query} onInput={e => setQuery(e.target.value)}
                                                    className='font-secundary text-base w-full px-4 py-3 border border-gray-300 font-normal rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-emerald-600' />
                                            </div>
                                            {results?.products?.nodes?.length > 0 && (
                                                <div className='mt-4'>
                                                    <h2 className='text-lg font-bold mb-2'>{t('nav.searchresults')}:</h2>
                                                    <ul>
                                                        {results?.products?.nodes?.map((product, i) => (
                                                            <li key={i} className={`bg-white rounded-lg py-2`}>
                                                                <Link href={product.link} className='flex' passHref>
                                                                    <div className='w-1/4 mr-4'>
                                                                        <NextImage quality={100} showDefault={true} className="rounded-sm" sourceUrl={product?.image?.sourceUrl || ''} altText={product?.image?.altText || ''} width={100} height={100} />
                                                                    </div>
                                                                    <div className='w-3/4'>
                                                                        <span className='font-secundary'>{product.name}</span>
                                                                        <p
                                                                            className='text-xs sm:text-sm line-clamp-3'
                                                                            dangerouslySetInnerHTML={{
                                                                                __html: product.shortDescription
                                                                            }}></p>
                                                                    </div>
                                                                </Link>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            )}
                                            {!loading && query.length > 0 && results?.products?.nodes?.length === 0 && (
                                                <div className='mt-4'>
                                                    <h2 className='text-lg font-bold mb-2'>{t('nav.nosearchresultsfound')}</h2>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </>
    );
};

export default SearchSidebar;

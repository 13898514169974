import { useEffect, useState } from "react";
import { getActivePromotion } from "../promotionConfig"

const PromotionBanner = ({ t }) => {
    const activePromotion = getActivePromotion();

    const today = new Date()
    const endDate = new Date(activePromotion.endDate)
    const diff = endDate - today

    const days = Math.floor(diff / (1000 * 60 * 60 * 24))
    const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)) - 1
    const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60))
    const seconds = Math.floor((diff % (1000 * 60)) / 1000)
    // make sure ints have 2 numbers, like 01 instead of 1
    const daysString = days;
    const hoursString = hours.toString().padStart(2, '0')
    const minutesString = minutes.toString().padStart(2, '0')
    const secondsString = seconds.toString().padStart(2, '0')

    const [timeLeft, setTimeLeft] = useState({
        days: daysString,
        hours: hoursString,
        minutes: minutesString,
        seconds: secondsString
    });

    useEffect(() => {
        const timer = setTimeout(() => {
            setTimeLeft({
                days: daysString,
                hours: hoursString,
                minutes: minutesString,
                seconds: secondsString
            });
        }, 1000);
        return () => clearTimeout(timer);
    }, [daysString, hoursString, minutesString, secondsString]);

    return (
        <div
            style={{
                backgroundColor: activePromotion.bgColor,
                color: activePromotion.textColor
            }}
            className="bg-black text-white text-center py-2">
            <p className="text-[13px] sm:text-base font-semibold">{t(activePromotion.discountLabel, { ns: 'common' })} {`${timeLeft.days}d ${timeLeft.hours}:${timeLeft.minutes}:${timeLeft.seconds}`}</p>
        </div>
    )
};

export default PromotionBanner;

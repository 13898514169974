import { gql } from "@apollo/client";

export const SEARCH_PRODUCT_BY_STRING = gql`
query SEARCH ($slug: String!) {
  products(where: {search: $slug, orderby: {
    order: ASC,
    field: NAME
  }}, first: 100) {
    nodes {
      name
      shortDescription
      image {
          id
          uri
          title
          srcSet
          sourceUrl
      }
      ... on SimpleProduct {
        link
      }
      ... on AnimalProduct {
        link
      }
      ... on CharacterProduct {
        link
      }
      ... on StarmapProduct {
        link
      }
      ... on StreetmapProduct {
        link
      }
      ... on StreetmapStockProduct {
        link
      }
      ... on TravelmapProduct {
        link
      }
      ... on VariableProduct {
        link
      }
      ... on ExternalProduct {
        link
      }
      ... on GroupProduct {
        link
      }
    }
  }
}`;
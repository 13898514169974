import { useContext } from 'react';
import { AppContext } from "../context/AppContext";
import Link from 'next/link';
import NextImage from '../../image';

const CartIcon = () => {

	const [ cart ] = useContext( AppContext );
	const productsCount = ( null !== cart && Object.keys( cart ).length ) ? cart.totalProductsCount : '';
	const totalPrice = ( null !== cart && Object.keys( cart ).length ) ? cart.totalProductsPrice : '';

	return (
		<div className="minicart">
			<div className="minicart icon_wrapper">
			<svg height={32} width={32} style={{transform: 'scale(1.25)'}} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 408.7 343.39">
				<path fill="#181818" d="M93,246.23c3.14-9.08,9.58-12.49,19-12.24,14.9.4,29.81.09,44.71.11,9.93,0,15,3.91,17.32,13.56,4.17,17.17,8.23,34.37,12.35,51.55.3,1.28.67,2.55,1.11,4.15h5.58q144.7,0,289.42,0a34.34,34.34,0,0,1,7.93.58,14.9,14.9,0,0,1,10.8,18.24c-5.12,21.45-10.48,42.84-15.77,64.25q-10.22,41.4-20.46,82.79c-2.8,11.36-7.36,15-19,15H214.44c-11.47,0-16.09-3.56-18.77-14.77q-23.83-99.61-47.61-199.24c-1.34-5.63-1.32-5.64-7.13-5.64-9.44,0-18.91-.33-28.33.1S96.35,261.81,93,252.62Zm373.89,88H194.77c9.55,40,19,79.66,28.53,119.41,1.14.06,1.93.13,2.71.13q104,0,207.91.06c2.59,0,3.61-.73,4.24-3.34,6.75-27.74,13.67-55.44,20.54-83.15C461.41,356.37,464.11,345.43,466.89,334.2Z" transform="translate(-93 -233.98)"/>
				<path fill="#181818" d="M431.44,506.5A35.44,35.44,0,1,1,396,542,35.37,35.37,0,0,1,431.44,506.5Zm-.17,21.28a14.18,14.18,0,0,0,.08,28.35,14.18,14.18,0,1,0-.08-28.35Z" transform="translate(-93 -233.98)"/>
				<rect fill="#181818" x="319.85" y="288.48" width="35.97" height="35.98"/>
				<path fill="#181818" d="M265.64,540.36A35.44,35.44,0,1,1,230,505,35.42,35.42,0,0,1,265.64,540.36Zm-21.31.16a14.17,14.17,0,1,0-28.33,0,14.17,14.17,0,0,0,28.33,0Z" transform="translate(-93 -233.98)"/>
				<rect fill="#181818" x="117.6" y="287" width="36.54" height="36.57"/>
				</svg>
				{ productsCount ? <span className="count">{ productsCount }</span> : '' }
			</div>
		</div>
	)
};

export default CartIcon;

import { Fragment, useState, useEffect, useContext } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import Link from 'next/dist/client/link';
import { PopupContext } from './context/PopupContext';
import { motion } from 'framer-motion';

const MobileNav = ({ t, isMenuOpen, setIsMenuOpen, mobileMenuData }) => {
    const [open, setOpen] = useState(false);
    const [menuData, setMenuData] = useState(mobileMenuData);
    const { setProductPopup, productPopup } = useContext(PopupContext);

    const [openSubmenu, setOpenSubmenu] = useState({
        0: false
    });

    useEffect(() => {
        if (isMenuOpen) {
            setOpen(true);
            setIsMenuOpen(false)
        }
    }, [isMenuOpen, setIsMenuOpen]);

    useEffect(() => {
        setMenuData(mobileMenuData.filter(item => item?.parentId === null));
    }, [mobileMenuData]);

    return (
        <>
            <Transition.Root unmount={false} show={open} as={Fragment}>
                <Dialog unmount={false} as="div" className="fixed inset-0 overflow-hidden z-50" onClose={setOpen}>
                    <div className="absolute inset-0 overflow-hidden">
                        <Transition.Child
                            as={Fragment}
                            unmount={false}
                            enter="ease-in-out duration-500"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in-out duration-500"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay onClick={() => setOpen(!open)} className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                        </Transition.Child>

                        <div style={{ right: '1.5rem' }} className="fixed inset-y-0 left-0 max-w-full flex">
                            <Transition.Child
                                as={Fragment}
                                unmount={false}
                                enter="transform transition ease-in-out duration-500 sm:duration-700"
                                enterFrom="-translate-x-full"
                                enterTo="translate-x-0"
                                leave="transform transition ease-in-out duration-500 sm:duration-700"
                                leaveFrom="translate-x-0"
                                leaveTo="-translate-x-full"
                            >
                                <div className="w-screen max-w-md" style={{ zIndex: '9999999' }}>
                                    <div className="h-full bg-white shadow-xl overflow-y-scroll">
                                        <div className="flex flex-row justify-between flex-1 pt-6 pb-2 overflow-y-auto px-4 sm:px-6">
                                            <div className="ml-3 h-7 flex items-center">
                                                <button
                                                    type="button"
                                                    style={{ WebkitTapHighlightColor: 'rgba(0, 0, 0, 0)' }}
                                                    className="-m-2 p-2 text-gray-600 my-auto hover:text-gray-700 focus:outline-none focus-visible:outline-none"
                                                    onClick={() => setOpen(false)}
                                                >
                                                    <span className="sr-only">Close</span>
                                                    <XIcon className="h-6 w-6" aria-hidden="true" />
                                                </button>
                                            </div>
                                            {true && (
                                                <button onClick={() => { setProductPopup({ ...productPopup, open: true }); setOpen(false); }} className='mr-4 max-w-fit text-xs btn-primary lightgreen py-3 px-5'>
                                                    {t('nav.designnow')}
                                                </button>
                                            )}
                                        </div>
                                        <nav className='py-6 px-4 sm:px-6'>
                                            <ul className="divide-y divide-gray-200">
                                                {menuData?.map((item, i) => (
                                                    <li className='mt-0 py-4 flex-col divide-y divide-gray-200 flex' key={i}>
                                                        {item?.childItems?.nodes?.length > 0 ? (
                                                            <>
                                                                <button onClick={() => setOpenSubmenu({ ...openSubmenu, [item.id]: openSubmenu[item.id] ? !openSubmenu[item.id] : true })} className="px-6 flex sm:px-8 w-full font-primary text-left font-medium tracking-wide text-gray-700 transition-colors duration-200 hover:text-gray-900">
                                                                    <span className='flex-grow'>{item.label}</span>
                                                                    <svg fill="none" viewBox="0 0 24 24" stroke="currentColor" className={`w-4 h-6 duration-200 transform ${openSubmenu[item.id] ? 'rotate-180' : ''}`}>
                                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
                                                                    </svg>
                                                                </button>
                                                                <motion.ul
                                                                    key={item.id}
                                                                    className="overflow-hidden px-8 divide-y divide-gray-200"
                                                                    animate={{ opacity: openSubmenu[item.id] ? 1 : 0, height: openSubmenu[item.id] ? '100%' : '0px', marginTop: openSubmenu[item.id] ? '1rem' : '0rem' }}
                                                                >
                                                                    {item?.childItems?.nodes?.map((subItem, i) => (
                                                                        <li key={i} className={`mt-0 ${i + 1 !== item?.childItems?.nodes.length ? 'py-4' : 'pt-4'} flex flex-col divide-y divide-gray-200`}>
                                                                            {subItem.childItems?.nodes?.length > 0 ? (
                                                                                <>
                                                                                    <button onClick={() => setOpenSubmenu({ ...openSubmenu, [subItem.id]: openSubmenu[subItem.id] ? !openSubmenu[subItem.id] : true })} className="flex w-full font-primary text-left font-medium tracking-wide text-gray-700 transition-colors duration-200 hover:text-gray-900">
                                                                                        <span className='flex-grow'>{subItem.label}</span>
                                                                                        <svg fill="none" viewBox="0 0 24 24" stroke="currentColor" className={`w-4 h-6 duration-200 transform ${openSubmenu[subItem.id] ? 'rotate-180' : ''}`}>
                                                                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
                                                                                        </svg>
                                                                                    </button>
                                                                                    <motion.ul
                                                                                        key={subItem.id}
                                                                                        className="overflow-hidden px-8 divide-y divide-gray-200"
                                                                                        animate={{ opacity: openSubmenu[subItem.id] ? 1 : 0, height: openSubmenu[subItem.id] ? '100%' : '0px', marginTop: openSubmenu[subItem.id] ? '1rem' : '0rem' }}
                                                                                    >
                                                                                        {subItem?.childItems?.nodes?.map((subSubItem, i) => (
                                                                                            <li key={i} className={`mt-0 ${i + 1 !== subItem?.childItems?.nodes.length ? 'py-4' : 'pt-4'} flex flex-col`}>
                                                                                                <Link href={subSubItem.path} className="flex w-full font-primary text-left font-medium tracking-wide text-gray-700 transition-colors duration-200 hover:text-gray-900">
                                                                                                    {subSubItem.label}
                                                                                                </Link>
                                                                                            </li>
                                                                                        ))}
                                                                                    </motion.ul>
                                                                                </>
                                                                            ) : (
                                                                                <Link
                                                                                    href={subItem.path}
                                                                                    className="sm:px-8 w-full font-medium tracking-wide text-gray-700 transition-colors duration-200 hover:text-gray-900"
                                                                                    onClick={() => setOpen(false)}
                                                                                >
                                                                                    {subItem.label}
                                                                                </Link>
                                                                            )}
                                                                        </li>
                                                                    ))}


                                                                </motion.ul>
                                                            </>) : (
                                                            <Link
                                                                href={item.path}
                                                                className="px-6 sm:px-8 w-full font-medium tracking-wide text-gray-700 transition-colors duration-200 hover:text-gray-900"
                                                                onClick={() => setOpen(false)}
                                                            >
                                                                {item.label}
                                                            </Link>
                                                        )}
                                                    </li>
                                                ))}
                                            </ul>
                                        </nav>
                                    </div>
                                </div>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </>
    );
};

export default MobileNav;

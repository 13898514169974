import Nav from "./Nav";

const Header = (props) => {
	return (
		<header className={`header ${props.sticky}`}>
			<section>
				<Nav search={props.search} ctaButton={props.ctaButton} mobileMenuData={props?.mobileMenuData} menuData={props?.menuData} />
			</section>
		</header>
	)
};

export default Header;

export const allPaymentOptions = [
    {
        id: 1,
        name: 'iDeal',
        height: 24,
        width: 27,
        imageUrl: "/images/v2/footer/ideal.svg",
        headerImageUrl: "/images/v2/header/ideal.svg",
        invertable: true
    },
    {
        id: 2,
        name: 'Creditcard',
        height: 24,
        width: 31,
        imageUrl: "/images/v2/footer/creditcard.svg",
        headerImageUrl: "/images/v2/header/creditcard.svg",
        invertable: true
    },
    {
        id: 3,
        name: 'Paypal',
        height: 24,
        width: 31,
        imageUrl: "/images/v2/footer/paypal.svg",
        headerImageUrl: "/images/v2/header/paypal.svg",
        invertable: true
    },
    {
        id: 4,
        name: 'Belfius',
        height: 24,
        width: 31,
        imageUrl: "/images/v2/footer/belfius.svg",
        headerImageUrl: "/images/v2/header/belfius.svg",
        invertable: true
    },
    {
        id: 5,
        name: 'Bancontact',
        height: 24,
        width: 31,
        imageUrl: "/images/v2/footer/bancontact.svg",
        headerImageUrl: "/images/v2/header/bancontact.svg",
        invertable: true
    },
    {
        id: 6,
        name: 'KBC',
        height: 24,
        width: 31,
        imageUrl: "/images/v2/footer/kbc.svg",
        headerImageUrl: "/images/v2/header/kbc.svg",
        invertable: true
    },
    {
        id: 7,
        name: 'Visa',
        height: 24,
        width: 31,
        imageUrl: "/images/v2/footer/visa.svg",
        headerImageUrl: "/images/v2/header/visa.svg",
        invertable: true
    },
    {
        id: 8,
        name: 'Apple pay',
        height: 24,
        width: 31,
        imageUrl: "/images/v2/footer/applepay.svg",
        headerImageUrl: "/images/v2/header/applepay.svg",
        invertable: true
    },
    {
        id: 9,
        name: 'Giropay',
        height: 28,
        width: 45,
        imageUrl: "/images/footer/giropay.svg",
        headerImageUrl: "/images/header/giropay.svg",
        invertable: false
    },
    {
        id: 10,
        name: 'Klarna',
        height: 25,
        width: 35,
        imageUrl: "/images/footer/klarna-paylater.svg",
        headerImageUrl: "/images/header/klarna-paylater.svg",
        invertable: false
    },
];

export const allShippingMethods = [
    {
        id: 1,
        name: 'PostNL',
        height: 24,
        width: 25,
        imageUrl: "/images/footer/postnl.svg"
    },
    {
        id: 2,
        name: 'DHL',
        height: 24,
        width: 40,
        imageUrl: "/images/footer/dhl.svg"
    }
];

export const allOptions = {
    'nl': ['iDeal', 'Creditcard', 'Paypal', 'Bancontact', 'Belfius', 'KBC', 'PostNL', 'Visa', 'Apple pay'],
    'de': ['Creditcard', 'Paypal', 'Giropay', 'Klarna', 'DHL'],
    'uk': ['Creditcard', 'Paypal', 'Apple pay', 'DHL'],
    'others': ['iDeal', 'Creditcard', 'Paypal', 'Bancontact', 'Belfius', 'KBC', 'PostNL']
}
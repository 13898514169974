import { Fragment, useState, useEffect, useContext } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import NextImage from '../../image';
import { v4 } from 'uuid';
import { amountStringToFloat, calculatePreviewSize, getFormattedCart, getUpdatedItems } from '../../functions';
import { useMutation, useQuery } from '@apollo/client';
import UPDATE_CART from '../../mutations/update-cart';
import GET_CART from '../../queries/get-cart-recalculate';
import { AppContext } from '../context/AppContext';
import { triggerAddOtherCartItemEvent, triggerBeginCheckoutEvent, triggerEditCartItemEvent, triggerShowBigImageEvent } from '../../events/executors';
import { Cross } from '../icons';
import { TrashIcon } from '@heroicons/react/outline';
import QtySelection from './QtySelection';
import { PopupContext } from '../context/PopupContext';
import { useRouter } from 'next/router';
import { getActivePromotion } from '../../promotionConfig';

const Minicart = () => {
    const { locale } = useRouter();
    const [open, setOpen] = useState(false);
    const { t } = useTranslation(['cart', 'common']);
    const [cart, setCart, miniCartOpen, setMiniCartOpen] = useContext(AppContext);
    const { productPopup, setProductPopup } = useContext(PopupContext);
    const [activeItem, setActiveItem] = useState();
    const [bigPreviewOpen, setBigPreviewOpen] = useState(false);

    const activePromotion = getActivePromotion();

    useEffect(() => {
        if (miniCartOpen) {
            setMiniCartOpen(false);
            setOpen(true);
        }
    }, [miniCartOpen, setMiniCartOpen]);

    const handleRemoveProductClick = (event, cartKey, products) => {
        event.stopPropagation();
        if (products.length) {

            // By passing the newQty to 0 in updateCart Mutation, it will remove the item.
            const newQty = 0;
            const updatedItems = getUpdatedItems(products, newQty, cartKey);
            updateCart({
                variables: {
                    input: {
                        clientMutationId: v4(),
                        items: updatedItems
                    }
                },
            });
        }
    };

    // Get Cart Data.
    const { loading, refetch } = useQuery(GET_CART, {
        fetchPolicy: 'no-cache',
        notifyOnNetworkStatusChange: true,
        onCompleted: (data) => {
            // Update cart in the localStorage.
            const updatedCart = getFormattedCart(data, locale);
            localStorage.setItem('woo-next-cart', JSON.stringify(updatedCart));
            // Update cart data in React Context.
            setCart(updatedCart);
        }
    });

    // Update Cart Mutation.
    const [updateCart, { data: updateCartResponse, loading: updateCartProcessing, error: updateCartError }] = useMutation(UPDATE_CART, {
        onCompleted: () => {
            refetch();
        },
        onError: (error) => {
            if (error) {
                const errorMessage = error?.graphQLErrors?.[0]?.message ? error.graphQLErrors[0].message : '';
            }
        }
    });

    const handleQtyChange = (event, cartKey) => {
        if (typeof window != "undefined") {

            event.stopPropagation();

            // If the previous update cart mutation request is still processing, then return.
            if (updateCartProcessing) {
                return;
            }

            // If the user tries to delete the count of product, set that to 1 by default ( This will not allow him to reduce it less than zero )
            const newQty = (event.target.value) ? parseInt(event.target.value) : 1;

            if (cart?.products?.length) {

                const updatedItems = getUpdatedItems(cart?.products, newQty, cartKey);

                updateCart({
                    variables: {
                        input: {
                            clientMutationId: v4(),
                            items: updatedItems
                        }
                    },
                });
            }

        }
    };
    const cartIsLoading = loading || updateCartProcessing;

    return <>
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="fixed inset-0 overflow-hidden z-40" onClose={setOpen}>
                <div className="absolute inset-0 overflow-hidden">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-in-out duration-500"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in-out duration-500"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="minicartWrapper fixed inset-y-0 right-0 pl-10 max-w-full flex">
                        <Transition.Child
                            as={Fragment}
                            enter="transform transition ease-in-out duration-500 sm:duration-700"
                            enterFrom="translate-x-full"
                            enterTo="translate-x-0"
                            leave="transform transition ease-in-out duration-500 sm:duration-700"
                            leaveFrom="translate-x-0"
                            leaveTo="translate-x-full"
                        >
                            <div className="w-screen max-w-md ml-auto" style={{ zIndex: '9999999' }}>
                                <div className="h-full relative flex flex-col bg-white shadow-xl overflow-y-scroll">
                                    {cartIsLoading && <MinicartSpinner />}
                                    <div className='bg-white py-4 px-4 sm:px-6 border-b border-gray-200'>
                                        <div className="flex items-start justify-between">
                                            <Dialog.Title className="text-lg font-medium text-gray-900">{t('minicart.mybasket')}</Dialog.Title>
                                            <div className="ml-3 h-7 flex items-center">
                                                <button
                                                    type="button"
                                                    style={{ WebkitTapHighlightColor: 'rgba(0, 0, 0, 0)' }}
                                                    className="-m-2 p-2 text-gray-400 hover:text-gray-500 focus:outline-none focus-visible:outline-none"
                                                    onClick={() => setOpen(false)}
                                                >
                                                    <span className="sr-only">{t('minicart.close')}</span>
                                                    <XIcon className="h-6 w-6" aria-hidden="true" />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex flex-col flex-1 py-6 pt-0 overflow-y-auto px-4 sm:px-6">
                                        {cart ?
                                            <div className="mt-8">
                                                <div className="flow-root">

                                                    <ul role="list" className="-my-6 divide-y divide-gray-200">
                                                        {cart?.products?.map((product, i) => (
                                                            <li key={product.productId + i} className={`${i === 0 ? 'pt-4' : null} py-6 grid grid-cols-3`}>
                                                                {product?.meta?.preview?.value ?
                                                                    <div style={{ display: 'flex', height: 'fit-content', width: 'fit-content' }} onClick={() => { setBigPreviewOpen(true); setActiveItem(product); triggerShowBigImageEvent() }} className={`mx-auto flex-shrink-0 col-span-1 rounded-md border border-gray-200 overflow-hidden`}>
                                                                        <div style={{
                                                                            display: 'flex', height: 'fit-content',
                                                                            borderWidth: product?.meta?.frame?.value !== 'none' || product?.meta?.materialHangingSystemCode?.value === 'canvas_framed_black' || product?.meta?.materialHangingSystemCode?.value === 'box_frame_wood_texture' ? '3px' : '0px',
                                                                            margin: product?.meta?.frame?.value !== 'none' || product?.meta?.materialHangingSystemCode?.value === 'canvas_framed_black' || product?.meta?.materialHangingSystemCode?.value === 'box_frame_wood_texture' ? '0.25rem' : '0px',
                                                                            borderColor: product?.meta?.frame?.value === 'pine' ? '#D8B589' :
                                                                                product?.meta?.frame?.value === 'black' ? '#000000' :
                                                                                    product?.meta?.frame?.value === 'white' ? '#ffffff' :
                                                                                        product?.meta?.materialHangingSystemCode?.value === 'canvas_framed_black' ? '#000000' :
                                                                                            product?.meta?.materialHangingSystemCode?.value === 'box_frame_wood_texture' ? '#D8B589' :
                                                                                                null
                                                                        }}
                                                                        >
                                                                            {product?.meta?.outputSizes?.width?.value && product?.meta?.outputSizes?.height?.value ? (
                                                                                <NextImage width={calculatePreviewSize(product?.meta?.isHorizontal?.value, parseInt(product?.meta?.outputSizes?.height?.value), parseInt(product?.meta?.outputSizes?.width?.value), 125).width} height={calculatePreviewSize(product?.meta?.isHorizontal?.value, parseInt(product?.meta?.outputSizes?.height?.value), parseInt(product?.meta?.outputSizes?.width?.value), 125).height} altText="Poster preview" sourceUrl={product.meta?.preview_small?.value ?? product.meta.preview.value} />
                                                                            ) : product?.meta.size?.value ? (
                                                                                <>
                                                                                    {product?.meta.size?.value === '1' ? <NextImage width={product?.meta?.isHorizontal?.value ? 100 : 75} height={product?.meta?.isHorizontal?.value ? 75 : 100} altText="Poster preview" sourceUrl={product.meta?.preview_small?.value ?? product.meta.preview.value} /> : null}
                                                                                    {product?.meta.size?.value === '2' ? <NextImage width={product?.meta?.isHorizontal?.value ? 100 : 80} height={product?.meta?.isHorizontal?.value ? 80 : 100} altText="Poster preview" sourceUrl={product.meta?.preview_small?.value ?? product.meta.preview.value} /> : null}
                                                                                    {product?.meta.size?.value === '3' ? <NextImage width={product?.meta?.isHorizontal?.value ? 100 : 72} height={product?.meta?.isHorizontal?.value ? 72 : 100} altText="Poster preview" sourceUrl={product.meta?.preview_small?.value ?? product.meta.preview.value} /> : null}
                                                                                </>) : <span></span>}
                                                                        </div>
                                                                    </div>
                                                                    :
                                                                    product?.image?.sourceUrl ?
                                                                        <div style={{ display: 'flex', height: 'fit-content', width: 'fit-content' }} className={`col-span-1 mx-auto flex-shrink-0 rounded-md border border-gray-200 overflow-hidden`}>
                                                                            {product?.variation ? (
                                                                                <NextImage className='object-contain' width={100} height={100} altText={product?.variation?.node?.image?.title} sourceUrl={product?.variation?.node?.image?.sourceUrl} />
                                                                            ) : (
                                                                                <NextImage className='object-contain' width={100} height={100} altText={product?.image?.title} sourceUrl={product?.image?.sourceUrl} />
                                                                            )}
                                                                        </div>
                                                                        : null}

                                                                <div className="ml-2 flex-1 flex flex-col col-span-2">
                                                                    <div>
                                                                        {product?.meta?.editUrl?.value ?
                                                                            <div className="flex justify-between gap-4 text-base font-medium text-gray-900">
                                                                                <a className='font-secundary' href={product?.meta?.editUrl?.value + `&cart-key=${product.cartKey}`} onClick={() => { triggerEditCartItemEvent(); setOpen(false) }} >{`${product.name} ${product?.meta?.material?.value?.toLowerCase() !== 'poster' ? product?.meta?.material?.value?.toLowerCase() : ''}`}<br />{product.meta?.sizeString?.value}</a>
                                                                                <TrashIcon onClick={(event) => { handleRemoveProductClick(event, product.cartKey, cart.products); }} className="h-6 min-h-[1.5rem] min-w-[1.5rem] w-6 p-1 bg-gray-200 bg-opacity-80 rounded-full text-gray-500 hover:text-gray-600 cursor-pointer ml-auto" aria-hidden="true" />
                                                                            </div>
                                                                            :
                                                                            <div className="flex justify-between gap-4 font-secundary text-base font-medium text-gray-900">
                                                                                {product?.variation ? (
                                                                                    <Link href={`/product/${product.slug}`} passHref>
                                                                                        {`${product?.variation?.node?.name}`}
                                                                                    </Link>
                                                                                ) : (
                                                                                    <>
                                                                                        {`${product.name} ${product?.meta?.material?.value.toLowerCase() !== 'poster' ? product?.meta?.material?.value?.toLowerCase() : null}`}<br />{product.meta?.sizeString?.value}
                                                                                    </>
                                                                                )}
                                                                                <TrashIcon onClick={(event) => { handleRemoveProductClick(event, product.cartKey, cart.products); }} className="h-6 min-h-[1.5rem] min-w-[1.5rem] w-6 p-1 bg-gray-200 bg-opacity-80 rounded-full text-gray-500 hover:text-gray-600 cursor-pointer ml-auto" aria-hidden="true" />
                                                                            </div>
                                                                        }
                                                                        <div>
                                                                            {product.meta?.text?.value ? <p className="text-gray-500 mt-1 text-xs font-normal">{product.meta?.text?.value}</p> : null}
                                                                            {product.meta?.text2?.value ? <p className="text-gray-500 mt-1 text-xs font-normal">{product.meta?.text2?.value}</p> : null}
                                                                            {product.meta?.text3?.value ? <p className="text-gray-500 mt-1 text-xs font-normal">{product.meta?.text3?.value}</p> : null}
                                                                            {product.meta?.frameString?.value ? <p className="text-gray-500 mt-1 text-xs font-normal">{product.meta?.frameString?.value}</p> : null}
                                                                            <p className="text-gray-500 mt-1 text-xs font-normal">{product.totalPrice}</p>
                                                                        </div>
                                                                        {product?.meta?.editUrl?.value ? (
                                                                            <div className="flex justify-between">
                                                                                <a href={product?.meta?.editUrl?.value + `&cart-key=${product.cartKey}`} onClick={() => { triggerEditCartItemEvent(); setOpen(false) }} type="button" className="font-medium mt-1 text-xs text-gray-700 hover:text-gray-800">
                                                                                    {t('cart.edit')}
                                                                                </a>
                                                                                <QtySelection mr="mr-0" curQty={product?.qty} handleOnChange={(event) => handleQtyChange(event, product?.cartKey)} />
                                                                            </div>
                                                                        ) : (
                                                                            product?.cartKey ? (
                                                                                <div className="flex justify-end">
                                                                                    <QtySelection mr="mr-0" curQty={product?.qty} handleOnChange={(event) => handleQtyChange(event, product?.cartKey)} />
                                                                                </div>
                                                                            ) : null)}
                                                                    </div>
                                                                </div>

                                                            </li>
                                                        ))}
                                                        {cart?.products?.length !== 1 && !activePromotion ? <li className="py-6 w-full">
                                                            <div className='flex'>
                                                                <button className='m-auto flex w-4/5 font-normal' onClick={() => { triggerAddOtherCartItemEvent(); setOpen(false); setProductPopup({ ...productPopup, open: true }) }}>
                                                                    <svg className='w-10 ml-auto mr-4 h-16' viewBox="0 0 33 33" fill="currentColor"><circle opacity=".9" cx="16.5" cy="16.5" r="16.5" fill="#FFE6C3"></circle><path d="M17 25.485V8.515M8.515 17h16.97" stroke="#000" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path></svg>
                                                                    <p className="font-regular text-sm m-auto text-gray-800">{t('cart.upsell')}</p>
                                                                </button>
                                                            </div>
                                                        </li> : null}
                                                    </ul>
                                                </div>
                                            </div>
                                            : <div className='my-24'>
                                                <h2 className='mb-2 mt-auto text-center font-sans text-2xl font-medium leading-none tracking-tight text-gray-900 sm:text-3xl'>{t('cart.emptybasket')}</h2>
                                                <div className="mt-6">
                                                    <button onClick={() => { setOpen(false); setProductPopup({ ...productPopup, open: true }) }} className="flex justify-center w-full sm:w-2/3 mx-auto items-center shadow-sm font-medium btn-primary lightgreen">
                                                        {t('cart.design')}
                                                    </button>
                                                </div>
                                            </div>}
                                    </div>
                                    {cart?.products?.length === 1 && !activePromotion ? <div className="py-6 w-full">
                                        <div className='flex'>
                                            <button className='m-auto flex w-4/5 font-normal' onClick={() => { triggerAddOtherCartItemEvent(); setOpen(false); setProductPopup({ ...productPopup, open: true }) }}>
                                                <svg className='w-7 ml-auto mr-4' viewBox="0 0 33 33" fill="currentColor"><circle opacity=".9" cx="16.5" cy="16.5" r="16.5" fill="#FFE6C3"></circle><path d="M17 25.485V8.515M8.515 17h16.97" stroke="#000" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path></svg>
                                                <p className="font-regular text-sm m-auto text-gray-800">{t('cart.upsell')}</p>
                                            </button>
                                        </div>
                                    </div> : null}
                                    {cart ?
                                        <div className="border-t border-gray-200 py-6 px-4 sm:px-6 font-secundary">
                                            {(('string' !== typeof cart.subTotalProductsPrice) ? cart.subTotalProductsPrice.toFixed(2) : cart.subTotalProductsPrice) === (('string' !== typeof cart.totalProductsPrice) ? cart.totalProductsPrice.toFixed(2) : cart.totalProductsPrice) ?
                                                <div className="flex justify-between text-base font-medium text-gray-900">
                                                    <p>{t('cart.subtotal')}</p>
                                                    <p>{('string' !== typeof cart.subTotalProductsPrice) ? cart.subTotalProductsPrice.toFixed(2) : cart.subTotalProductsPrice}</p>
                                                </div>
                                                :
                                                <>
                                                    <ul role="list" className="px-0">
                                                        <li className="flex justify-between pb-2">
                                                            <p className="text-gray-500 text-sm font-normal">{t('cart.subtotal')}</p>
                                                            <p className="text-gray-800 text-sm font-medium w-1/3 text-right">{('string' !== typeof cart.subTotalProductsPrice) ? cart.subTotalProductsPrice.toFixed(2) : cart.subTotalProductsPrice}</p>
                                                        </li>
                                                        {cart.discountTotal && cart.discountTotal.match(/[1-9]/) && (
                                                            <li className="flex justify-between pb-2">
                                                                <div>
                                                                    <p className="text-gray-500 text-sm font-normal">{t('minicart.discount')}</p>
                                                                </div>
                                                                <p className="text-gray-800 text-sm font-medium w-1/3 text-right">- {cart.discountTotal}</p>
                                                            </li>
                                                        )}
                                                        <li className="flex justify-between">
                                                            <p className="text-gray-800 font-medium">{t('cart.total')}</p>
                                                            <p className="text-gray-800 font-medium w-1/3 text-right">{('string' !== typeof cart.totalProductsPrice) ? cart.totalProductsPrice.toFixed(2) : cart.totalProductsPrice}</p>
                                                        </li>
                                                    </ul>
                                                </>}

                                            {/* <p className="mt-0.5 text-sm text-gray-500">{t('minicart.shipping')}</p> */}
                                            <div className="mt-4">
                                                <Link
                                                    href="/checkout"
                                                    passHref
                                                    onClick={() => {
                                                        setOpen(false);
                                                        triggerBeginCheckoutEvent({
                                                            items: cart.products.map(product => {
                                                                return {
                                                                    "id": product.productId,
                                                                    "name": product.name,
                                                                    "quantity": product.qty,
                                                                    "price": product.price
                                                                }
                                                            }),
                                                            value: amountStringToFloat(cart.totalProductsPrice, locale),
                                                            currency: t('currency', { ns: 'common' }),
                                                            couponCode: cart.appliedCoupons.find(coupon => coupon.code !== t('bulkdiscount', { ns: 'common' }))?.code,
                                                            checkoutButton: 3
                                                        });
                                                    }}
                                                    className="flex justify-center items-center shadow-md btn-primary lightgreen">

                                                    {t('minicart.proceedtocheckout')}

                                                </Link>
                                                <Link
                                                    href="/cart"
                                                    passHref
                                                    onClick={() => setOpen(false)}
                                                    className="flex justify-center items-center px-6 py-3 border mt-2 rounded-[0.25em] shadow-md text-gray-700 text-base font-medium bg-gray-50 border-[#405952] hover:border-[#2D3C38]">

                                                    {t('shoppingcart')}

                                                </Link>
                                            </div>
                                        </div>
                                        : null}
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                    {bigPreviewOpen && <BigPreviewImg product={activeItem} setOpenPreview={setBigPreviewOpen} />}
                </div>
            </Dialog>
        </Transition.Root>
    </>;
};

const BigPreviewImg = ({ product, setOpenPreview }) => {
    const size = product?.meta?.size?.value;
    const imgSrc = product?.meta?.preview?.value;

    return (
        <div onClick={() => setOpenPreview(false)} className="fixed inset-0 z-50 bg-gray-800 bg-opacity-60">
            <div className="flex w-4/5 sm:w-full items-center justify-center absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                <div className='relative'>
                    {size ?
                        <div style={{
                            display: 'flex', height: 'fit-content',
                            borderWidth: product?.meta?.frame?.value !== 'none' || product?.meta?.materialHangingSystemCode?.value === 'canvas_framed_black' || product?.meta?.materialHangingSystemCode?.value === 'box_frame_wood_texture' ? '0.625em' : '0px',
                            margin: '0.25rem',
                            borderColor: product?.meta?.frame?.value === 'pine' ? '#D8B589' :
                                product?.meta?.frame?.value === 'black' ? '#000000' :
                                    product?.meta?.frame?.value === 'white' ? '#ffffff' :
                                        product?.meta?.materialHangingSystemCode?.value === 'canvas_framed_black' ? '#000000' :
                                            product?.meta?.materialHangingSystemCode?.value === 'box_frame_wood_texture' ? '#D8B589' :
                                                null
                        }}
                        >
                            {product?.meta?.outputSizes?.width?.value && product?.meta?.outputSizes?.height?.value ? (
                                <>
                                    <Cross onClick={() => setOpenPreview(false)} circleFill="#7e7e7e" fill="#ffffff" className="cursor-pointer absolute -top-4 -right-4 z-50" width={32} height={32} />
                                    <NextImage width={calculatePreviewSize(product?.meta?.isHorizontal?.value, parseInt(product?.meta?.outputSizes?.height?.value), parseInt(product?.meta?.outputSizes?.width?.value), 540).width} height={calculatePreviewSize(product?.meta?.isHorizontal?.value, parseInt(product?.meta?.outputSizes?.height?.value), parseInt(product?.meta?.outputSizes?.width?.value), 540).height} altText="Poster preview" sourceUrl={imgSrc} />
                                </>
                            ) : product?.meta.size?.value ? (
                                <>
                                    <Cross onClick={() => setOpenPreview(false)} circleFill="#7e7e7e" fill="#ffffff" className="cursor-pointer absolute -top-4 -right-4 z-50" width={32} height={32} />
                                    {size === '1' ? <NextImage width={product?.meta?.isHorizontal?.value ? 540 : 405} height={product?.meta?.isHorizontal?.value ? 405 : 540} altText="Poster preview" sourceUrl={imgSrc} /> : null}
                                    {size === '2' ? <NextImage width={product?.meta?.isHorizontal?.value ? 540 : 432} height={product?.meta?.isHorizontal?.value ? 432 : 540} altText="Poster preview" sourceUrl={imgSrc} /> : null}
                                    {size === '3' ? <NextImage width={product?.meta?.isHorizontal?.value ? 540 : 385} height={product?.meta?.isHorizontal?.value ? 385 : 540} altText="Poster preview" sourceUrl={imgSrc} /> : null}
                                </>
                            ) : null}
                        </div>
                        : null}
                </div>
            </div>
        </div>
    )
};

const MinicartSpinner = () => (
    <div className="absolute inset-0 z-50 bg-gray-800 bg-opacity-10">
        <div className="flex items-center justify-center absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
            <div className="w-16 h-16 border-b-2 border-gray-900 rounded-full animate-spin "></div>
        </div>
    </div>
);

export default Minicart;
